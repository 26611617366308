export async function binlookup(bin) {

    const url = 'https://lookup.binlist.net/';
    const response = await fetch(url + bin);

    if (response.status !== 200) {
        throw new Error('BIN lookup failed');
    }

    return await response.json();
}