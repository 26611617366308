<style>
.card-item {
  padding: 0px !important;
  margin: 0px !important;
  width: 100% !important;
  max-width: 100% !important;
}

.card-item > div {
  box-shadow: none !important;
}

.card-item__chip {
  display: none;
}

.card-stack .card-box:hover,
.card-stack .card-box:active {
  transition: box-shadow 0.3s ease;
  box-shadow: 0 20px 60px 0 rgb(14 42 90 / 55%) !important;
}

@media (min-width: 450px) {
  .card-stack {
    margin-top: 12px !important;
    max-width: 450px !important;
  }

  .card-stack .card-box {
    border-radius: 15px 15px 0 0;
    max-height: 90px;
    overflow: hidden;
    position: relative;
    margin-top: -12px;
    transition: max-height 300ms ease-in-out;
  }

  .card-stack .card-box:hover,
  .card-stack .card-box:active {
    max-height: 255px !important;
    transition: max-height 400ms ease-in-out;
    transition-delay: 300ms;
  }

  .card-stack .card-box:last-child {
    max-height: 270px !important;
  }

  .card-stack .card-box:hover:last-child,
  .card-stack .card-box:active:last-child {
    margin-top: -12px !important;
    max-height: 270px !important;
  }

  .card-item__content {
    margin-top: -13px !important;
  }
}

@media (max-width: 450px) {
  .card-stack {
    margin-top: 12px !important;
    max-width: 450px !important;
  }

  .card-stack .card-box {
    border-radius: 15px 15px 0 0;
    max-height: 80px;
    overflow: hidden;
    position: relative;
    margin-top: -12px;
    transition: max-height 300ms ease-in-out;
  }

  .card-stack .card-box:hover,
  .card-stack .card-box:active {
    max-height: 205px !important;
    transition: max-height 400ms ease-in-out;
    transition-delay: 300ms;
  }

  .card-stack .card-box:last-child {
    max-height: 220px !important;
  }

  .card-stack .card-box:hover:last-child,
  .card-stack .card-box:active:last-child {
    margin-top: -12px !important;
    max-height: 220px !important;
  }

  .card-item__content {
    margin-top: -13px !important;
  }
}

.card-stack .card-box .delete-button,
.card-stack .card-box .delete-button {
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 10px;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.card-stack .card-box:hover .delete-button,
.card-stack .card-box:active .delete-button {
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 10px;
  opacity: 0.8;
}

.card-stack .card-box:hover .delete-button:hover,
.card-stack .card-box:active .delete-button:active {
  opacity: 1;
}

.tap-icon {
  position: absolute;
  z-index: 9;
  font-size: 20px;
  color: white;
  width: 100%;
  text-align: center;
  top: 15px;
  animation: pulse 1s linear infinite;
  transition: opacity 300ms ease-in-out;
  opacity: 0.7;
}

.card-stack .card-box:hover .tap-icon,
.card-stack .card-box:active .tap-icon {
  opacity: 0;
}

/* .card-stack .card-box:last-child .tap-icon,
.card-stack .card-box:last-child .tap-icon {
  opacity: 0;
} */

@keyframes pulse {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}
</style>

<template>
  <div class="main">
    <article v-bind:class="darkMode" class="card p-2 mb-5" style="font-size: 13px;">
      <div class="row">
        <div class="col d-flex justify-content-center align-items-center pr-0">
          <i style="font-size: 22px" class="fas fa-info-circle"></i>
        </div>
        <div class="col-10 pl-1">Tap a card to see more details. Tap delete to remove card from account.</div>
      </div>
    </article>

    <div class="card-stack">
      <div v-if="cards != null && cards.length > 0">
        <div v-for="card in cards" :key="card.cardID" class="card-box">
          <button v-on:click="deleteCard(card)" class="btn btn-sm btn-danger delete-button">Delete</button>
          <i class="tap-icon fas fa-hand-pointer"></i>
          <vue-paycard :value-fields="card" :background-image="card.cardImage" />
        </div>
      </div>
      <div v-else class="w-100 d-flex justify-content-center align-items-center">
        <div v-if="cards != null">
          <p>No cards added yet.</p>
        </div>
        <div v-else class="spinner-border m-5" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAuth } from 'firebase/auth';
import { VuePaycard } from "vue-paycard";
import { binlookup } from '../../../helpers/binlookup';
import { darkModeStyle } from '../../../assets/js/darkMode';
import { getRemoteConfigValue } from '@assets/js/remoteConfig';

export default {
  components: {
    VuePaycard,
  },
  data: () => ({
    user: null,
    cards: null,
    darkMode: darkModeStyle('bg-dark text-light', 'bg-light text-dark'),
    darkModeText: darkModeStyle('text-light', 'text-dark'),
    darkModeBtn: darkModeStyle('btn-dark', 'btn-light'),
    click: 0,
  }),
  methods: {
    async getCardImage(bin) {
      var cardType = await binlookup(bin);

      if (typeof cardType.bank != 'undefined') {
        const response = await fetch(`/assets/cards/${cardType.bank.name}.png`);

        if (!response.ok) {
          return "";
        }

        return response.blob().then((blob) => { return URL.createObjectURL(blob) });
      }

      return "";
    },
    async loadCards() {

      const customerID = this.user.square_customer_id;
      var response = await this.$store.dispatch('loadCards', { customerID: customerID });
      console.log(response.cards);
      if (typeof response.cards == 'undefined') {
        this.cards = [];
        return;
      }

      var newCards = response.cards.map((card) => {
        var binSplit = card.bin.replace(/(\d{4})/g, '$1 ').replace(/(^\s+|\s+$)/, '');
        var cardNum = `${binSplit}00 0000 ${card.last_4}`;

        return {
          cardName: `${this.user.fname} ${this.user.lname}`,
          cardNumber: cardNum,
          cardMonth: card.exp_month,
          cardYear: card.exp_year,
          cardCvv: '123',
          cardBin: card.bin,
          cardImage: '',
          cardID: card.id,
        }
      });

      this.cards = newCards;
    },
    async deleteCard(card) {


      const { result, dialog } = await this.$buefy.dialog.prompt({
        message: `To delete ${card.cardName}'s card, please type ${card.cardNumber.substr(-4)} in the field below.`,
        closeOnConfirm: false,
        closeOnCancel: false,
        inputAttrs: { type: 'number', placeholder: '2398', maxlength: 4 },
        trapFocus: true,
        confirmText: 'Delete',
        confirmVariant: 'is-danger',
        cancelText: 'Cancel'
      });

      if (result != false) {
        if (result == card.cardNumber.substr(-4)) {

          try {
            await this.deleteCardAPI(card, this.user);
            this.$BuefySimplified.Toast.Success(`${card.cardName}'s card ending in ${card.cardNumber.substr(-4)} has been deleted.`);
            dialog.close();
            return;

          } catch (error) {
            this.$BuefySimplified.Toast.Danger(error.message);
            dialog.close();
            return;
          }

        } else {
          this.$BuefySimplified.Toast.Danger('Incorrect last 4 digits of card number.');
          dialog.close();
          return;
        }
      }

      this.$BuefySimplified.Toast.Warning(`${card.cardName}'s card ending in ${card.cardNumber.substr(-4)} has not been deleted.`);
      dialog.close();

    },
    async deleteCardAPI(card, user) {

      const apiURL = await getRemoteConfigValue('APIurl');

      const app = this.$firebaseApp
      const auth = getAuth(app)
      const token = await auth.currentUser.getIdToken();

      if (typeof token == 'undefined') {
        throw new Error('No token');
      }

      if (typeof card.cardID == 'undefined') {
        throw new Error('Card ID is undefined.');
      }

      if (typeof user.square_customer_id == 'undefined') {
        throw new Error('Customer ID is undefined.');
      }

      const cardID = card.cardID;
      const customerID = user.square_customer_id;


      const response = await fetch(`${apiURL.asString()}/api/cards/${cardID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`,
          "csrf-token": this.$cookies.get('XSRF-TOKEN'),
        }
      });


      if (response.status == 200) {

        this.cards = this.cards.filter((c) => {
          return c.cardID != card.cardID;
        });

        return;

      } else {
        const error = await response.json();

        if (typeof error.error.message != 'undefined') {
          throw new Error(error.error.message);
        } else {
          throw new Error('Unknown error.');
        }
      }

    }
  },
  computed: {
    userData: function() {
      var newValue = this.$store.getters.user;

      if (newValue != null) {
        this.loadCards(newValue.square_customer_id, `${newValue.fname} ${newValue.lname}`);
      }
    },
    cardData: function() {
      return this.cards;
    },
  },
  watch: {
    '$store.getters.user': function(newValue) {
      if (newValue != null) {
        this.user = newValue;
        this.loadCards();
      }
    },
    cardData: function(newValue) {
      if (newValue != null) {
        newValue.forEach((card) => {
          this.getCardImage(card.cardBin).then((image) => {
            card.cardImage = image;
          });
        });
      }
    },
  },
  mounted() {
    this.userData;
  },
}
</script>